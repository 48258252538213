<template>
  <div class="carousel-container-mobile">
    <div class="img-part">
      <ClientOnly>
        <Carousel
          ref="imageCarousel"
          v-bind="imageCarouselProps"
          @slide-start="(data) => syncSliders2(data as SlideStartEvent)"
          @slide-end="(data) => resetLeft(data as SlideEndEvent)"
        >
          <Slide
            v-for="(slide, index) in sliderItems"
            :key="slide.title"
            class="slide-content"
          >
            <NuxtLink
              :to="slide.ctaRoute"
              class="slide-link"
            >
              <div style="height: 100%">
                <img
                  :src="slide.image"
                  :alt="slide.title"
                  :style="{
                    transform: `translate3d(${imgsLeft[index]}%, 0, 0)`,
                  }"
                  loading="lazy"
                />
                <div
                  v-if="slide.insertText"
                  class="text-img"
                >
                  <p
                    v-if="slide.insertDetail"
                    class="small"
                  >
                    {{ slide.insertDetail }}
                  </p>
                  <p class="big">{{ slide.insertText }}</p>
                </div>
              </div>
            </NuxtLink>
          </Slide>
        </Carousel>
      </ClientOnly>
    </div>
    <div class="text-part">
      <ClientOnly>
        <Carousel
          ref="textCarousel"
          v-bind="textCarouselProps"
          @slide-start="(data) => syncSliders1(data as SlideStartEvent)"
        >
          <Slide
            v-for="slide in sliderItems"
            :key="slide.title"
            class="slide-content"
          >
            <NuxtLink
              :to="slide.ctaRoute"
              class="slide-link"
            >
              <div class="inner-slide">
                <p class="sup-title">{{ slide.typeTitle }}</p>
                <h2>{{ capitalize(slide.title) }}</h2>
                <p
                  v-if="slide.sliderDescription"
                  class="description"
                >
                  <span>{{ slide.sliderDescription }}</span>
                </p>
                <ButtonSimple
                  :label="slide.ctaLabel"
                  color="white"
                />
              </div>
            </NuxtLink>
          </Slide>
        </Carousel>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  CarouselInstance,
  CarouselProps,
  FormattedDoNotMiss,
  SlideEndEvent,
  SlideStartEvent,
} from '@/types'
import { Carousel, Slide } from 'vue3-carousel'

const props = defineProps<{
  sliderItems: FormattedDoNotMiss[]
}>()

const imgsLeft = ref<number[]>([])

const textCarousel = ref<CarouselInstance>(null)
const imageCarousel = ref<CarouselInstance>(null)

const imageCarouselProps = {
  transition: 800,
} satisfies CarouselProps

const textCarouselProps = {
  transition: 800,
} satisfies CarouselProps

const syncSliders1 = ({
  currentSlideIndex,
  slidingToIndex,
}: SlideStartEvent) => {
  if (
    currentSlideIndex === props.sliderItems.length - 1 &&
    slidingToIndex === 0
  ) {
    imageCarousel.value?.next()
  } else {
    imageCarousel.value?.slideTo(slidingToIndex)
  }
}

const syncSliders2 = ({
  currentSlideIndex,
  slidingToIndex,
}: SlideStartEvent) => {
  imgsLeft.value.splice(slidingToIndex, 1, 0)
  imgsLeft.value.splice(currentSlideIndex, 1, 100)

  if (
    currentSlideIndex === props.sliderItems.length - 1 &&
    slidingToIndex === 0
  ) {
    textCarousel.value?.next()
  } else {
    textCarousel.value?.slideTo(slidingToIndex)
  }
}

const resetLeft = ({ currentSlideIndex }: SlideEndEvent) => {
  if (currentSlideIndex > 0) {
    imgsLeft.value.splice(currentSlideIndex - 1, 1, 0)
  }

  if (currentSlideIndex < imgsLeft.value.length - 1) {
    imgsLeft.value.splice(currentSlideIndex + 1, 1, 0)
  }
}
onMounted(async () => {
  await nextTick()

  imgsLeft.value = new Array(props.sliderItems.length).fill(0)
})
</script>

<style lang="scss">
.carousel-container-mobile {
  width: 100%;
  height: 100%;
  background: $blue;

  .carousel__viewport,
  .carousel__track,
  .carousel {
    height: 100%;
  }

  .carousel__track,
  .carousel__slide {
    display: flex !important;
    height: 100%;
  }

  .carousel__slide > div {
    flex-grow: 1;
  }

  .carousel__slide {
    &:not(.carousel__slide--active) {
      pointer-events: none;
    }
  }

  .slide-link {
    display: block;
    height: 100%;
    color: inherit;
    text-decoration: none;
  }

  .text-part {
    width: 100%;
    height: 100%;

    .carousel__slide {
      align-self: stretch;
      height: auto;
    }

    .carousel__track {
      padding-bottom: 4.5rem !important;
    }

    .slick-dots {
      bottom: 2rem;
      left: 50%;
      width: auto;
      transform: translateX(-50%);

      li {
        margin: 0;
      }

      li button::before {
        color: $white;
        font-size: 0.4rem;
      }

      li.slick-active button::before {
        font-size: 0.9rem;
        opacity: 1;
      }
    }

    .slide-content {
      width: 100%;
      height: 100%;
      padding-top: 2.5rem;

      .inner-slide {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        padding: 0 2.5rem;
        text-align: left;
      }

      .sup-title {
        margin-bottom: 1rem;
        color: $white;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.8rem;
        letter-spacing: 0.14rem;
        text-transform: uppercase;
        opacity: 0.65;
      }

      h2 {
        display: -webkit-box;
        height: 8.2rem;
        margin-bottom: 2rem;
        overflow: hidden;
        color: $white;
        font-family: SST;
        font-size: 3.2rem;
        font-weight: 400;
        line-height: 4.1rem;
        letter-spacing: 0;
        text-transform: none;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
      }

      .description {
        margin-bottom: 2rem;
        color: $white;
        font-size: 1.4rem;
        white-space: pre-line;
      }

      .button {
        margin-top: auto;
      }
    }
  }

  .img-part {
    width: 100%;
    height: 66vw;

    .slide-content {
      position: relative;
      width: 100%;
      height: 100%;

      .slide-link {
        display: block;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      transition: transform 0.8s ease;
      pointer-events: none;
      object-fit: cover;
      object-position: bottom right;
    }

    .text-img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 20.6rem;
      padding: 1rem 2rem;
      color: $blue;
      font-family: 'SST Condensed';
      text-transform: uppercase;
      background: $white;

      .small {
        font-size: 1rem;
        line-height: 1.3rem;
      }

      .big {
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 2.1rem;
      }
    }
  }
}
</style>
