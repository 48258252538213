<template>
  <div class="carousel-container">
    <div class="text-part">
      <ClientOnly>
        <Carousel
          ref="textCarousel"
          v-bind="textCarouselProps"
          @slide-start="(data) => beforeTextSlide(data as SlideStartEvent)"
          @slide-end="afterTextSlide"
        >
          <Slide
            v-for="(slide, index) in sliderItems"
            :key="slide.title"
            class="slide"
          >
            <NuxtLink
              :to="
                !draggingImages && !draggingText ? slide.ctaRoute : undefined
              "
              class="slide-link"
            >
              <div class="inner-slide">
                <div class="slide-count">
                  <p class="current">{{ zeroFormatter(index + 1) }}</p>
                  <div class="separator" />
                  <p class="next">{{ zeroFormatter(sliderItems.length) }}</p>
                </div>
                <template v-if="slide.titleImage">
                  <h2>
                    <img
                      :src="slide.titleImage"
                      :alt="slide.title"
                      width="490"
                    />
                    <span>{{ capitalize(slide.title) }}</span>
                  </h2>
                </template>
                <template v-else>
                  <p class="sup-title">{{ slide.typeTitle }}</p>
                  <h2>{{ capitalize(slide.title) }}</h2>
                </template>
                <p
                  v-if="slide.sliderDescription"
                  class="description"
                  data-test="make it long"
                >
                  <span>{{ slide.sliderDescription }}</span>
                </p>
                <div v-if="'prize' in slide">
                  <p class="prize">{{ slide.prize }}</p>
                </div>
              </div>
              <div class="button">{{ slide.ctaLabel }}</div>
            </NuxtLink>
          </Slide>
          <template #addons>
            <Navigation>
              <template #prev> </template>
              <template #next>
                <div class="custom-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 23.64 14.973"
                  >
                    <path
                      d="M23.64 7.487a.915.915 0 0 0-.222-.542l-6.3-6.7A.789.789 0 0 0 16 .215a.812.812 0 0 0-.031 1.114l5.06 5.371H.788a.788.788 0 0 0 0 1.576h20.241l-5.061 5.369A.825.825 0 0 0 16 14.758a.789.789 0 0 0 1.114-.031l6.3-6.7a.7.7 0 0 0 .222-.542Z"
                    />
                  </svg>
                </div>
              </template>
            </Navigation>
          </template>
        </Carousel>
      </ClientOnly>
    </div>
    <div class="img-part">
      <ClientOnly>
        <Carousel
          ref="imageCarousel"
          v-bind="imageCarouselProps"
          @slide-start="(data) => beforeImageSlide(data as SlideStartEvent)"
          @slide-end="afterImageSlide"
        >
          <Slide
            v-for="slide in sliderItems"
            :key="slide.title"
            class="slide-content"
          >
            <NuxtLink
              :to="
                !draggingImages && !draggingText ? slide.ctaRoute : undefined
              "
              class="slide-link"
            >
              <div style="height: 100%">
                <img
                  :src="slide.image"
                  :alt="slide.title"
                  loading="lazy"
                />
                <div
                  v-if="slide.insertText"
                  class="text-img"
                >
                  <p
                    v-if="slide.insertDetail"
                    class="small"
                  >
                    {{ slide.insertDetail }}
                  </p>
                  <p class="big">{{ slide.insertText }}</p>
                </div>
              </div>
            </NuxtLink>
          </Slide>
        </Carousel>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  CarouselInstance,
  CarouselProps,
  FormattedDoNotMiss,
  SlideStartEvent,
} from '@/types'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import { zeroFormatter } from '~/utils/formatHelper'

defineProps<{
  sliderItems: FormattedDoNotMiss[]
}>()

const textCarousel = ref<CarouselInstance>(null)
const imageCarousel = ref<CarouselInstance>(null)

const imageCarouselProps = {
  transition: 800,
} satisfies CarouselProps

const textCarouselProps = {
  transition: 800,
} satisfies CarouselProps

const draggingImages = ref(false)
const draggingText = ref(false)

const beforeTextSlide = ({ slidingToIndex }: SlideStartEvent) => {
  if (draggingImages.value) return

  draggingImages.value = true

  imageCarousel.value?.slideTo(slidingToIndex)
}

const beforeImageSlide = ({ slidingToIndex }: SlideStartEvent) => {
  if (draggingText.value) return

  draggingText.value = true

  textCarousel.value?.slideTo(slidingToIndex)
}

const afterImageSlide = () => {
  draggingImages.value = false
}

const afterTextSlide = () => {
  draggingText.value = false
}
</script>

<style lang="scss">
.carousel-container {
  display: flex;
  width: 100%;
  height: 52.4rem;
  background: $blue;

  .carousel__viewport,
  .carousel__track,
  .carousel {
    height: 100%;
  }

  .carousel__track,
  .carousel__slide {
    display: flex !important;
    height: 100%;
    pointer-events: none;

    &.carousel__slide--active {
      pointer-events: all;
    }
  }

  .carousel__slide > div {
    flex-grow: 1;
  }

  .slide-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: inherit;
    text-decoration: none;
  }

  .text-part {
    width: 40%;
    min-width: 51rem;
    height: 100%;
    background: $blue;

    .carousel__next {
      top: initial;
      right: 8rem;
      bottom: 6rem;

      svg {
        path {
          fill: #fff;
        }
      }

      &::before {
        content: '';
      }
    }

    .slick-dots {
      bottom: 6rem;
      left: 6rem;
      width: auto;

      li {
        margin: 0;
      }

      li button::before {
        color: $white;
        font-size: 0.4rem;
      }

      li.slick-active button::before {
        font-size: 0.9rem;
        opacity: 1;
      }
    }

    .carousel__slide {
      width: 100%;
      height: 100%;

      .slide-link {
        display: inline-flex !important;
        flex-direction: column;

        // justify-content: flex-end;
        align-items: flex-start;

        // padding-bottom: 22rem;
        padding-top: 10rem;
        overflow: hidden;

        .inner-slide {
          max-height: 40rem;
        }
      }

      .button {
        z-index: 1;
        display: inline-block;
        align-items: center;
        height: 4.6rem;
        margin-top: 3rem;
        margin-left: 6rem;
        padding: 0 2rem;
        color: #00439c;
        font-family: inherit;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 4.6rem;
        text-decoration: inherit;
        background: $white;
        border: 1px solid $white;
        transition:
          opacity 0.35s,
          color 0.35s,
          background 0.35s,
          border 0.35s;
      }

      .inner-slide {
        width: 100%;
        padding: 0 6rem;
        text-align: left;
      }

      .slide-count {
        position: absolute;
        top: 6rem;
        left: 6rem;
        display: inline-flex;
        align-items: center;

        .current {
          color: $white;
        }

        .current,
        .next {
          font-size: 1.4rem;
          line-height: 1.8rem;
        }

        .separator {
          width: 3.7rem;
          height: 1px;
          margin: 0 1.2rem;
          background: $darkBlue;
        }

        .next {
          color: $darkBlue;
        }
      }

      .sup-title {
        color: #fff;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.8rem;
        letter-spacing: 0.14rem;
        text-transform: uppercase;
      }

      h2 {
        margin-bottom: 2rem;
        color: #fff;
        font-family: SST;
        font-size: 3.2rem;
        font-weight: 400;
        line-height: 4.1rem;
        letter-spacing: 0;
        text-transform: none;

        @media screen and (max-width: 1600px) {
          font-size: 2.8rem;
        }

        @media screen and (max-width: 950px) {
          font-size: 2rem;
        }

        span {
          display: none;
        }

        img {
          display: block;
          max-width: 80%;
          margin: 0 auto;
        }
      }

      .description {
        margin-bottom: 2rem;
        color: #fff;
        font-size: 1.4rem;
        line-height: 1.6rem;
        white-space: pre-line;
      }

      .prize {
        color: #fff;
        font-family: 'SST Condensed';
        font-size: 1.8rem;
        font-weight: bold;
        white-space: pre-wrap;

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 0.1rem;
          margin: 2rem 0;
          background-color: #fff;
          opacity: 0.7;
        }
      }
    }
  }

  .img-part {
    width: 60%;
    height: 100%;

    .carousel__slide {
      position: relative;
      width: 100%;
      height: 100%;

      .slide-link {
        display: block;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      transition: transform 0.8s ease;
      pointer-events: none;
      object-fit: cover;
      object-position: bottom right;
    }

    .text-img {
      position: absolute;
      top: 40%;
      left: 0;
      width: 25rem;
      padding: 2rem 4rem;
      color: $blue;
      font-family: 'SST Condensed';
      text-transform: uppercase;
      background: $white;

      .small {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }

      .big {
        font-size: 2.2rem;
        font-weight: bold;
        line-height: 2.4rem;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .carousel-container {
    .text-part {
      width: 51rem;

      .carousel__next {
        right: 2.5rem;
        bottom: 5.6rem;
      }

      .slick-dots {
        bottom: 6rem;
        left: 6rem;
      }
    }

    .img-part {
      width: calc(100vw - 51rem - 8rem);
    }
  }
}

@media screen and (max-width: 1023px) {
  .carousel-container {
    .text-part {
      .slick-dots {
        bottom: 6rem;
        left: 6rem;
      }

      .slide-content {
        .slide-count,
        .description {
          display: none;
        }
      }
    }

    .img-part {
      .text-img {
        width: 20.6rem;
        padding: 1rem 2rem;

        .small {
          font-size: 1rem;
          line-height: 1.3rem;
        }

        .big {
          font-size: 1.6rem;
          line-height: 2.1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .carousel-container {
    .text-part {
      width: 50%;
      min-width: 50%;

      .carousel__next {
        right: 2.5rem;
        bottom: 5.6rem;
      }

      .slick-dots {
        bottom: 6rem;
        left: 6rem;
      }
    }

    .img-part {
      width: 50%;
    }
  }
}

@media screen and (max-width: 375px) {
  .carousel-container {
    flex-direction: column-reverse;
    height: 63rem;
    line-height: 1.3;

    .text-part {
      width: 100%;
      min-width: 100%;

      .slick-dots {
        bottom: 3.9rem;
        left: 50%;
        transform: translateX(-50%);
      }

      .carousel__next {
        display: none;
      }

      .slide-content {
        .slide-count,
        .description {
          display: none;
        }

        .sup-title {
          margin-bottom: 1rem;
          opacity: 0.65;
        }

        .slide-link {
          display: block;
          padding-bottom: 17.6rem;
        }

        .inner-slide {
          padding: 0 2.5rem;
        }

        .button {
          bottom: 9.7rem;
          left: 2.5rem;
        }
      }
    }

    .img-part {
      width: 100%;
      height: 21.2rem;

      .text-img {
        top: auto;
        bottom: 0;
        width: 20.6rem;
        padding: 1rem 2rem;

        .small {
          font-size: 1rem;
        }

        .big {
          font-size: 1.6rem;
        }
      }
    }
  }
}
</style>
