<template>
  <section
    v-if="items.length"
    class="do-not-miss-section section"
  >
    <div class="title-line">
      <h2 class="title-strat">À ne pas manquer</h2>
    </div>
    <div class="slider">
      <div v-if="isMobile || isLargeMobile || isTabletPortrait">
        <DoNotMissMobile :slider-items="items" />
      </div>
      <div v-else>
        <DoNotMiss :slider-items="items" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { DoNotMiss } from '@/types'
import { differenceInDays } from 'date-fns'
import { isDefined } from '@/utils/helpers'

const route = useRoute()

const props = defineProps<{
  sliderItems: DoNotMiss
}>()

const { isMobile, isLargeMobile, isTabletPortrait } = useCustomMediaQuery()

const items = computed(() => {
  return props.sliderItems
    .map((item) => {
      const {
        slide_type,
        title,
        description,
        slider_description: sliderDescription,
        image_title,
        slug,
        prize,
      } = item

      const baseItem = {
        title,
        description,
        sliderDescription,
        titleImage: image_title?.url,
      }

      if (slide_type === 'wallContest' || slide_type === 'speedContest') {
        const { bg_mobile, bg_desktop } = item
        const typeTitle = 'Concours'
        const ctaLabel = 'Participer'
        const ctaRoute = `/${
          slide_type === 'wallContest'
            ? ROUTES.CONTEST_WALL
            : ROUTES.CONTEST_RECORD
        }/${slug}`

        const insertDetail = ''
        let insertText = ''
        let image = bg_desktop?.url

        if (route.params.contestName === slug) return

        if (
          (isMobile.value || isLargeMobile.value || isTabletPortrait.value) &&
          bg_mobile?.url
        ) {
          image = bg_mobile?.url
        }

        const remainingDays = differenceInDays(
          item.contest_end ? new Date(item.contest_end) : new Date(),
          new Date(),
        )

        if (remainingDays === 0) {
          insertText = `dernier jour pour participer`
        } else {
          insertText = `${remainingDays} ${pluralize(
            'jour',
            remainingDays,
          )} ${pluralize('restant', remainingDays)} pour participer`
        }

        return {
          ...baseItem,
          slide_type,
          typeTitle,
          image,
          ctaRoute,
          ctaLabel,
          insertDetail,
          insertText,
          prize: 'À GAGNER :  \n' + prize,
        }
      }

      const {
        image_desktop,
        image_mobile,
        starts_at,
        places_count,
        hide_days_left,
      } = item

      let typeTitle = ''
      let ctaRoute = ''
      let ctaLabel = ''
      let insertDetail = ''
      let insertText = ''
      let image = image_desktop?.url

      if (
        (isMobile.value || isLargeMobile.value || isTabletPortrait.value) &&
        image_mobile?.url
      ) {
        image = image_mobile.url
      }

      if (slide_type === 'event') {
        typeTitle = 'Évènement'
        ctaLabel = `S'inscrire`
        ctaRoute = `/evenement/${slug}`
        insertDetail = `le ${dateToString(starts_at)}`
        insertText = `${places_count} ${pluralize(
          'place',
          places_count,
        )} ${pluralize('disponible', places_count)}`
      } else if (slide_type === 'contest') {
        typeTitle = 'Concours'
        ctaLabel = `Participer`
        ctaRoute = `/${ROUTES.CONTEST_SIMPLE}/${slug}`

        if (!hide_days_left) {
          const remainingDays = differenceInDays(
            item.displayed_ends_at
              ? new Date(item.displayed_ends_at)
              : new Date(),
            new Date(),
          )

          if (remainingDays === 0) {
            insertText = `dernier jour pour participer`
          } else {
            insertText = `${remainingDays} ${pluralize(
              'jour',
              remainingDays,
            )} ${pluralize('restant', remainingDays)} pour participer`
          }
        }
      }

      return {
        ...baseItem,
        slide_type,
        typeTitle,
        image,
        ctaRoute,
        ctaLabel,
        insertDetail,
        insertText,
        prize,
      }
    })
    .filter(isDefined)
})
</script>

<style lang="scss" scoped>
.do-not-miss-section {
  position: relative;
  z-index: 1;
  padding-right: 0;

  @media screen and (max-width: 1023px) {
    padding-left: 0;
  }

  @media screen and (max-width: 700px) {
    margin-top: 5rem;
  }

  .title-line {
    @media screen and (max-width: 1023px) {
      margin-left: 8rem;
    }

    @media screen and (max-width: 700px) {
      margin-left: 0;
    }
  }
}
</style>
